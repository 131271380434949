<template>
    <div class="form-auth-forgot" :class="{ page }">
        <div class="form-auth-forgot__content">
            <slot></slot>
            <div class="form-auth-forgot__left">
                <h2 class="form-auth-forgot__title">
                    {{ $lang.components.formForgotPassword.noproblem_go_back }}
                </h2>
                <ButtonGeneric
                    class="form-auth-forgot__change-modal-button"
                    :info="{
                        text: $lang.components.formForgotPassword.goback,
                        handler: changeModal,
                    }"
                />
                <p class="form-auth-forgot__contact-link">
                    {{ $lang.components.formForgotPassword.have_troubles }}
                    <NuxtLink no-prefetch :to="`/${$lang.routes.contact}`" rel="follow">{{
                        $lang.components.formForgotPassword.contact_us
                    }}</NuxtLink>
                </p>
            </div>
            <div class="form-auth-forgot__right">
                <div class="space-y-3">
                    <h2 class="form-auth-forgot__normal-title">
                        {{ $lang.components.formForgotPassword.change_password }}
                    </h2>
                    <p class="form-auth-forgot__description">
                        {{ $lang.components.formForgotPassword.check_email }}
                    </p>
                </div>
                <div class="space-y-3">
                    <h2 class="form-auth-forgot__normal-title">
                        {{ $lang.components.formForgotPassword.set_email }}
                    </h2>
                    <form class="form-auth-forgot__form-login" @submit.prevent>
                        <InputGeneric
                            v-model="form.data.email"
                            class="form-auth-forgot__field"
                            type="text"
                            name="email"
                            :placeholder="$lang.components.formForgotPassword.email"
                            :invalid="form.validationStatus.email"
                        >
                            {{ form.validationStatus.email }}
                        </InputGeneric>

                        <ButtonGeneric
                            class="form-auth-forgot__digits-submit-button"
                            :info="{
                                text: $lang.components.formForgotPassword.login_without_password,
                                icon: $assets.secondary.magic,
                                handler: () => handleAuthStrategy('withCode'),
                            }"
                        ></ButtonGeneric>
                        <ButtonGeneric
                            class="form-auth-forgot__password-submit-button"
                            :info="{
                                text: $lang.components.formForgotPassword.restore_password,
                                icon: $assets.primary.mail,
                                handler: () => handleAuthStrategy('withToken'),
                            }"
                        ></ButtonGeneric>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useAuthStore } from '~/store/auth'
import { useRootStore } from '~/store/root'

import type { Api } from '~~/global'

export default defineComponent({
    name: 'FormForgotPassword',
    props: {
        page: Boolean,
    },
    data() {
        const AuthStore = useAuthStore()
        const RootStore = useRootStore()
        return {
            RootStore,
            AuthStore,
            form: {
                data: {
                    email: '',
                },
                validationStatus: {
                    email: false as string | boolean,
                },
            },
        }
    },
    methods: {
        async handleAuthStrategy(strategy: 'withCode' | 'withToken') {
            this.RootStore.setLoadingStatus(true)

            this.form.validationStatus.email = false

            const { buildHeaders, baseURL, endpoints } = useApiConfig()

            const result = await $fetch<
                Api.Responses.Auth.SuccessResetPasswordInit | Api.Responses.Auth.FailedResetPasswordInit
            >(endpoints.authentication.recoveryInit[strategy].path, {
                method: 'POST',

                headers: buildHeaders(this.AuthStore.SessionToken || undefined),
                baseURL,
                body: this.form.data,
            }).catch((e) => e.data)

            if (result.feedback === 'not_found') {
                this.form.validationStatus.email = result.data.message
            }

            if (result.feedback === 'mail_sent') {
                const { setRecoveryEmail } = useAuthStore()
                setRecoveryEmail(this.form.data.email)
                if (!this.page) {
                    this.AuthStore.setAuthModal({
                        type: strategy === 'withCode' ? 'email-sign-in' : 'reset-password-init',
                    })
                } else {
                    const routeName = strategy === 'withCode' ? '/' + this.$lang.routes.loginWithoutPass : '/'

                    this.$router.push(routeName).then(() => {
                        if (routeName === '/') {
                            this.AuthStore.setAuthModal({
                                type: 'reset-password-init',
                            })
                        }
                    })
                }
            }

            if (result.feedback === 'not_found') {
                this.form.validationStatus.email = result.data.message
            }
            this.RootStore.setLoadingStatus(false)
        },
        changeModal() {
            if (this.page) {
                this.$router.push('/' + this.$lang.routes.login)
            } else {
                this.AuthStore.setAuthModal({
                    type: 'sign-in',
                })
            }
        },
    },
})
</script>

<style lang="postcss" scoped>
.form-auth-forgot {
    @apply overflow-hidden rounded-xl;
    &__content {
        @apply relative overflow-hidden bg-site-background md:flex;
    }
    &__close-button {
        @apply fixed right-4 top-4 flex items-center space-x-1 text-sm text-gray-800;

        img {
            @apply h-4 w-4 saturate-150;
        }
    }
    &__left {
        @apply flex flex-col justify-center space-y-3 p-5 md:w-1/2 md:flex-none;
    }
    &.page {
        .form-auth-forgot {
            &__content {
                @apply bg-white;
            }
            @screen md {
                &__left {
                    @apply border-r border-gray-200;
                }
            }
        }
    }

    &__title {
        @apply text-center text-2xl;
    }
    &__description {
        @apply text-sm text-gray-800;
    }
    &__change-modal-button {
        @apply w-full border-2 border-site-secondary bg-white text-site-secondary;
    }
    @screen lg {
        &__change-modal-button {
            @apply transition-colors;
            &:hover {
                @apply border-site-secondary bg-site-secondary text-white;
            }
        }
    }
    &__right {
        @apply flex flex-col justify-between space-y-3 border-t border-t-gray-200 bg-white p-5 md:w-1/2 md:flex-none lg:border-t-0;
    }
    &__normal-title {
        @apply text-lg font-semibold;
    }
    &__form-login {
        @apply space-y-3;
    }
    &__digits-submit-button {
        @apply w-full border-2 border-site-secondary bg-white text-site-secondary;
    }
    @screen lg {
        &__digits-submit-button {
            @apply transition-colors;
            img {
                @apply transition-all;
            }
            &:hover {
                @apply bg-site-secondary text-white transition-colors;
                img {
                    @apply brightness-0 invert;
                }
            }
        }
    }
    &__password-submit-button {
        @apply w-full border-2 border-site-primary bg-white text-site-primary;
    }
    @screen lg {
        &__password-submit-button {
            @apply transition-colors;
            img {
                @apply transition-all;
            }
            &:hover {
                @apply bg-site-primary text-white transition-colors;
                img {
                    @apply brightness-0 invert;
                }
            }
        }
    }
    &__contact-link {
        @apply text-center text-sm text-gray-800;
        a {
            @apply text-site-primary underline lg:no-underline lg:hover:underline;
        }
    }
}
</style>
